/*
Template Name: Velzon - Admin & Dashboard Template
Author: Themesbrand
Website: https://themesbrand.com/
Contact: support@themesbrand.com
File: Custom Css File
*/

@import "../../../../app/layouts/topbar/topbar.component.scss";
@import "../../../../app/account/auth/twostep/basic/basic.component.scss";
@import "../../../../app/pages/parent/student-layout/student-layout.component.scss";
@import "../../../../app/pages/nurse/admin/school-settings/school-settings.component.scss";
@import "../../../../app/pages/parent/sections/consent-for-treatment/consent-for-treatment.component.scss";
@import "../../../../app/pages/nurse/admin/manage-groups/manage-groups.component.scss";

.autosave-alert {
    width: 240px;
    position: absolute;
    top: 11%;
    left: 44%;
}

.ngx-file-drop__drop-zone-label {
    padding: .5rem;
}

.dz-btn {
    @extend .btn;
    @extend .btn-primary;
}

.dz-content {
    // import class px-1 from bootstrap
    @extend .px-1 !optional;
}