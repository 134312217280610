.student-layout {
  display: grid;
  grid-template-columns: 260px minmax(0, 1fr);
  gap: 2rem;

  @media (max-width: 857px) {
    grid-template-columns: 220px minmax(0, 1fr);
    gap: 1rem;
  }

  @media (max-width: 768px) {
    grid-template-columns: minmax(0, 1fr);
  }
}

.student-form-row {
  display: flex;
  max-width: 550px;

  & > * {
    width: 100%;

    &:not(:last-child) {
      margin-right: 1rem;

      @media (max-width: 650px) {
        margin-right: 0.5rem;
      }
    }
  }
}

.otc-ng-select {
  font-size: 14px;
}

.otc-ng-select .ng-select-container {
  padding: 7px 16px;
}

.otc-ng-select .ng-value-container {
  gap: 0.5rem 1.4rem;
}

// signature styles reset
.signature-md {
  max-width: 630px;
}

.signature-pad {
  background: white !important;
}
.signature-pad canvas {
  border: 0 !important;
  background: #fcfcfc;
  margin: 0 auto;
  display: block;
}

.signature-reflection {
  max-width: 300px;
  margin: 0 auto;
}

.signature-reflection img {
  width: 100%;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  top: 50%;
  transform: translateY(-50%);
  padding-bottom: 0 !important;
}

.health-question {
  max-width: 750px;
  justify-content: space-between;
}

// drop zone fix
.dz-remove {
  z-index: 1000;
}
