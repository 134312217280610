 
@import "../../../../node_modules/bootstrap/scss/functions";
@import "../../../../node_modules/bootstrap/scss/variables";
@import "../../../assets/scss/config/saas/variables";
@import "../../../assets/scss/config/saas/theme-dark";
@import "../../../assets/scss/config/saas/theme-light";
$jarvis-primary: #13294B;
$parent-primary: #4B9CD3;
$nurse-primary: #4F758B;

$header-bg-jarvis:                               var(--#{$variable-prefix}header-bg-jarvis);
$header-bg-parent:                               var(--#{$variable-prefix}header-bg-parent);
$header-bg-nurse:                                var(--#{$variable-prefix}header-bg-nurse);


[data-layout-mode="dark"]{
    --#{$variable-prefix}header-bg-jarvis: #{$jarvis-primary};
    --#{$variable-prefix}header-bg-parent: #{$parent-primary};
    --#{$variable-prefix}header-bg-nurse: #{$nurse-primary};
}

[data-layout-mode="light"]{
    --#{$variable-prefix}header-bg-jarvis: #{$jarvis-primary};
    --#{$variable-prefix}header-bg-parent: #{$parent-primary};
    --#{$variable-prefix}header-bg-nurse: #{$nurse-primary};
}

#page-topbar.jarvis-topbar {
    background-color: $header-bg-jarvis;
    .topbar-user {
        @media (min-width: 768px) {
            background-color: $header-bg-jarvis;
        }
    }
}

#page-topbar.parent-topbar {
    background-color: #4B9CD3;
    .topbar-user {
        @media (min-width: 768px) {
            background-color: #4B9CD3;
        }
    }
}

#page-topbar.nurse-topbar {
    background-color: $header-bg-nurse;
    .topbar-user {
        @media (min-width: 768px) {
            background-color: $header-bg-nurse;
        }
    }
}
[configuration="nurse"] {
    .navbar-menu .navbar-nav .nav-link {
        color: $nurse-primary;
    }
}
[configuration="parent"] {
    .navbar-menu .navbar-nav .nav-link {
        color: $parent-primary;
    }
}
[configuration="jarvis"] {
    .navbar-menu .navbar-nav .nav-link {
        color: $jarvis-primary;
    }
}